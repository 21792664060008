var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("HomePage", {
    staticClass: "main-content",
    attrs: {
      "background-img": _vm.backgroundImg,
      "logo-img": _vm.logoImg,
      "swipe-img-list": _vm.swipeImgList,
      "app-code": _vm.APP_CODE,
      "api-hosts": _vm.API_HOSTS,
      "button-style-ios": _vm.buttonStyleIOS,
      "button-style-android": _vm.buttonStyleAndroid,
      "button-style-pc": _vm.buttonStylePC,
      "logo-icon": _vm.logoIcon,
      iosButtonImg: _vm.downloadBtnImg,
      androidButtonImg: _vm.downloadBtnImg,
      foregroundImg: _vm.foregroundImg,
    },
    scopedSlots: _vm._u([
      {
        key: "home_logo",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "logo_header" },
              [
                _c("DecodeImage", { attrs: { path: _vm.logoTitle } }),
                _c("DecodeImage", { attrs: { path: _vm.logoIcon } }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }