<template>
  <HomePage
    class="main-content"
    :background-img="backgroundImg"
    :logo-img="logoImg"
    :swipe-img-list="swipeImgList"
    :app-code="APP_CODE"
    :api-hosts="API_HOSTS"
    :button-style-ios="buttonStyleIOS"
    :button-style-android="buttonStyleAndroid"
    :button-style-pc="buttonStylePC"
    :logo-icon="logoIcon"
    :iosButtonImg="downloadBtnImg"
    :androidButtonImg="downloadBtnImg"
    :foregroundImg="foregroundImg"
  >
    <template v-slot:home_logo>
      <div class="logo_header">
        <DecodeImage :path="logoTitle" />
        <DecodeImage :path="logoIcon" />
      </div>
    </template>
  </HomePage>
</template>

<script>
import HomePage from '@/components/common/home-page/index.vue'
import { APP_CODE, API_HOSTS } from '../config'

import backgroundImg from './assets/images/bg.png'
import logoImg from './assets/images/logo_1.png'
import logoTitle from './assets/images/logo_title.png'
import logoIcon from './assets/images/logo_icon.png'
import downloadBtnImg from './assets/images/download_btn.png'
import foregroundImg from './assets/images/foreground.png'
import DecodeImage from '@/components/common/DecodeImage.vue'
import { uploadVisitEvent } from '@/../utils'

export default {
  components: {
    HomePage,
    DecodeImage
  },
  data() {
    return {
      backgroundImg,
      logoImg,
      logoTitle,
      logoIcon,
      downloadBtnImg,
      foregroundImg,
      swipeImgList: [
        {
          bg: require('./assets/images/swipe_bg_1.png'),
          title: require('./assets/images/swipe_title_1.png')
        },
        {
          bg: require('./assets/images/swipe_bg_2.png'),
          title: require('./assets/images/swipe_title_2.png')
        },
        {
          bg: require('./assets/images/swipe_bg_3.png'),
          title: require('./assets/images/swipe_title_3.png')
        },
        {
          bg: require('./assets/images/swipe_bg_4.png'),
          title: require('./assets/images/swipe_title_4.png')
        }
      ],
      APP_CODE,
      API_HOSTS,
      buttonStyleIOS: {
        border: 'none',
        // backgroundImage: `url(${button_IOS})`,
        // backgroundSize: '100% 100%',
        // backgroundRepeat: 'no-repeat',
        background: 'transparent',
        height: '53px'
      },
      buttonStyleAndroid: {
        // position: 'relative',
        border: 'none',
        // backgroundImage: `url(${button_IOS})`,
        // backgroundSize: '100% 100%',
        // backgroundRepeat: 'no-repeat',
        background: 'transparent',
        height: '53px'
      },
      buttonStylePC: {
        borderRadius: '10px',
        border: '0.5px solid #FFFFDA',
        background:
          'linear-gradient(180deg, #FF45F8 0%, #923DFF 57%, #49208B 100%)'
      }
    }
  },
  created() {
    uploadVisitEvent(APP_CODE)
  }
}
</script>

<style>
@import url('../../../assets/css/minireset.min.css');
@import url('../../../assets/css/common.css');
@import url('../common.css');
</style>

<style scoped>
.main-content ::v-deep .home__logo__img {
  height: 40px;
  margin: auto;
}

.main-content ::v-deep .home__bottom--ios > .wrapper__buttons {
  width: 100%;
}

.main-content ::v-deep .home__bottom--android > .wrapper__buttons {
  width: 100%;
}

.logo_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  width: 100%;
}

img {
  height: 45px;
}

.main-content ::v-deep .home__swipe__item__title img {
  width: 100% !important;
}
</style>
